import { Box, Center, VStack } from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { SSOButton } from './SSOButton';
import { GoogleSSOButton } from './GoogleSSOButton';
import { FacebookSSOButton } from './FacebookSSOButton';
import { TextError } from './FormError';

export function SSOLoginButtons({
   loginHandler,
   error,
   setError,
   register,
}: {
   loginHandler: (response: any) => void;
   error: string | null;
   setError: (error: string) => void;
   register?: boolean;
}) {
   if (!(App.ssoLoginUrl || App.googleLoginEnabled || App.facebookLoginEnabled)) {
      return null;
   }

   return (
      <>
         <VStack spacing={3} width="100%">
            {App.ssoLoginUrl && (
               <SSOButton
                  href={App.ssoLoginUrl}
                  text={register ? _js('Sign up with SSO') : _js('Sign in with SSO')}
               />
            )}
            {App.googleLoginEnabled && (
               <GoogleSSOButton
                  setError={setError}
                  loginHandler={loginHandler}
                  text={register ? _js('Sign up with Google') : _js('Sign in with Google')}
               />
            )}
            <FacebookSSOButton
               setError={setError}
               loginHandler={loginHandler}
               text={register ? _js('Sign up with Facebook') : _js('Sign in with Facebook')}
            />
            <TextError>{error}</TextError>
         </VStack>
         <Box
            display="flex"
            placeContent="center"
            height={5}
            position="relative"
            width="100%"
            sx={{
               '&:before': {
                  content: '""',
                  borderBottomWidth: '1px',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(50%)',
                  width: 'inherit',
                  zIndex: 0,
               },
            }}
         >
            <Center
               as="span"
               bgColor="white"
               display="inline-flex"
               fontSize="md"
               height="inherit"
               paddingX={6}
               position="relative"
            >
               {_js('or')}
            </Center>
         </Box>
      </>
   );
}
