import { Flex, useToken, Text } from '@chakra-ui/react';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { LogIn } from './LogIn';

export const LogInPage = () => {
   const gray50 = useToken('colors', 'gray.50');
   return (
      <>
         <style>{`:where(body) { background-color: ${gray50}; }`}</style>
         <Flex marginY={14} justify="center" data-testid="loginContainer">
            <LogIn />
         </Flex>
         <Text
            dangerouslySetInnerHTML={{
               __html: App.dataProtectionMessage,
            }}
         ></Text>
      </>
   );
};

initializeChakraComponent('LogInPage', LogInPage);
