import { FormErrorMessage, FormErrorMessageProps, Flex, FlexProps } from '@chakra-ui/react';
import { FaIcon } from '@ifixit/icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

export function FormError(props: FormErrorMessageProps) {
   return (
      <FormErrorMessage
         color="red.500"
         fontSize="md"
         fontWeight={400}
         marginY={2}
         textAlign="left"
         {...props}
      >
         <FaIcon
            icon={faCircleExclamation}
            marginRight={1}
            marginTop={0.5}
            alignSelf="flex-start"
         />
         {props.children}
      </FormErrorMessage>
   );
}

export function TextError(props: FlexProps) {
   if (!props.children) {
      return null;
   }

   return (
      <Flex
         color="red.500"
         fontSize="md"
         fontWeight={400}
         marginY={2}
         textAlign="left"
         alignItems="center"
         {...props}
      >
         <FaIcon
            icon={faCircleExclamation}
            marginRight={1}
            marginTop={0.5}
            alignSelf="flex-start"
         />
         {props.children}
      </Flex>
   );
}
