import { useRef, useState, useEffect } from 'react';
import { Auth } from 'Shared/auth';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { Box, Button } from '@chakra-ui/react';
import GoogleLogo from 'Shared/icons/logos/google.svg';

export function GoogleSSOButton({
   loginHandler,
   setError,
   text,
}: {
   loginHandler: (response: any) => void;
   setError: (error: string) => void;
   text: string;
}) {
   const wrapperRef = useRef<HTMLDivElement>(null);
   const [googleButton, setGoogleButton] = useState<HTMLElement | null>(null);
   const [showCustomButton, setShowCustomButton] = useState(true);

   useEffect(() => {
      (window as any).onGoogleLibraryLoad = () => {
         // Firefox can't find the button without this because it's in a cross-origin iframe.
         // There's a brief moment where the button isn't in an iframe and Firefox can find it.
         // requestAnimationFrame seems to get the button in this moment consistently.
         window.requestAnimationFrame(() => {
            const googleButton = wrapperRef.current?.querySelector('div[role=button]');
            if (googleButton instanceof HTMLElement) {
               setGoogleButton(googleButton);
            } else {
               // Fall back to the default button
               setShowCustomButton(false);
            }
         });
      };
   }, [wrapperRef.current]);

   const signinCallback = (credentialResponse: CredentialResponse) => {
      if (credentialResponse.credential) {
         (Auth as any).sendLogin({
            googleOAuthData: credentialResponse,
            ajax: true,
            handler: loginHandler,
         });
      } else {
         setError(App.loginMessages.OAuth2_ACCESS_TOKEN);
      }
   };

   const errorCallback = () => {
      setError(App.loginMessages.OAuth2_ERROR);
   };

   return (
      <GoogleOAuthProvider clientId={App.googleOAuth2Clientid}>
         {showCustomButton && (
            <Button
               fontWeight="medium"
               minHeight="40px"
               width="100%"
               px={0}
               onClick={() => {
                  if (googleButton instanceof HTMLElement) {
                     googleButton.click();
                     return;
                  }
                  const newGoogleButton = wrapperRef.current?.querySelector('div[role=button]');
                  if (newGoogleButton instanceof HTMLElement) {
                     setGoogleButton(newGoogleButton);
                     newGoogleButton.click();
                  }
               }}
            >
               <GoogleLogo />
               <Box as="span" marginLeft={2}>
                  {text}
               </Box>
            </Button>
         )}
         <Box display={showCustomButton ? 'none' : ''} ref={wrapperRef}>
            <GoogleLogin onSuccess={signinCallback} onError={errorCallback} locale={App.lang} />
         </Box>
      </GoogleOAuthProvider>
   );
}
