import { Button, Box } from '@chakra-ui/react';
import FacebookLogo from 'Shared/icons/logos/facebook.svg';
import { _js } from '@ifixit/localize';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { Auth } from 'Shared/auth';

export function FacebookSSOButton({
   loginHandler,
   setError,
   text,
}: {
   loginHandler: (response: any) => void;
   setError: (error: string) => void;
   text: string;
}) {
   if (!App.facebookLoginEnabled) {
      return null;
   }

   return (
      <FacebookLogin
         appId={App.facebookAppId}
         initParams={{
            version: 'v18.0',
            cookie: true,
         }}
         onSuccess={() => {
            (Auth as any).clearCookies();
            (Auth as any).sendFacebookLogin(loginHandler);
         }}
         onFail={(_error: any) => {
            setError(_js('Unable to complete Facebook login.'));
         }}
         render={(renderProps: any) => (
            <Button
               fontWeight="medium"
               minHeight="40px"
               width="100%"
               px={0}
               onClick={renderProps.onClick}
            >
               <FacebookLogo />
               <Box as="span" marginLeft={2}>
                  {text}
               </Box>
            </Button>
         )}
      />
   );
}
