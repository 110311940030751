import { Button, Box, Link } from '@chakra-ui/react';
import { FaIcon } from '@ifixit/icons';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';

export function SSOButton({ text, href }: { text: string; href: string }) {
   return (
      <Link href={href} width="100%" id="ssoLogin">
         <Button fontWeight="medium" minHeight="40px" width="100%" px={0}>
            <FaIcon icon={faSignIn} boxSize="20px" color="brand.500" />
            <Box as="span" marginLeft={2}>
               {text}
            </Box>
         </Button>
      </Link>
   );
}
